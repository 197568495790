import React, { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";

import BlueScreen from "components/BlueScreen";
import axios from "axios";

const ErrorBoundaryLayer: FC<{ children: any }> = ({ children }) => {
  const slackNotify = async (text: string) => {
    let data = JSON.stringify({
      text,
    });

    let config: any = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}authentication-slackPrompt`,
      headers: {
        "Content-type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("slack", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log("slack", error);
      });
  };
  const onError = (error: Error, info: { componentStack: string }) => {
    const errorString = JSON.stringify(
      error,
      Object.getOwnPropertyNames(error)
    );
    const componentStack = JSON.stringify(info.componentStack);
    const text = `Error occurred:\n${errorString}\nComponent stack: ${componentStack}`;
    // slackNotify(text)
    //   .then((response) => {})
    //   .catch((error) => {
    //     console.log("slack", error);
    //   });
  };

  return (
    <ErrorBoundary FallbackComponent={BlueScreen} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryLayer;
