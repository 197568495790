export const contentMap = {
  engagement: `
  <div>
    <h3>Overall Engagement Trend</h3>
    <ul>
      <!-- Insights on engagement trends go here -->
      <!-- <li>* Engagement Trend 1</li> -->
      <!-- <li>* Engagement Trend 2</li> -->
      <!-- <li>* Engagement Trend 3</li> -->
    </ul>
    <h3>Engagement Distribution</h3>
    <ul>
      <!-- Insights on engagement distribution go here -->
      <!-- <li>* Engagement Distribution 1</li> -->
      <!-- <li>* Engagement Distribution 2</li> -->
    </ul>
    <h3>Engagement Performance Analysis</h3>
    <ul>
      <!-- Performance analysis insights go here -->
      <!-- <li>* Performance Analysis 1</li> -->
    </ul>
  </div>
  `,
  reach: `
  <div>
    <h3>Reach Performance</h3>
    <ul>
      <!-- Insights on reach analysis go here -->
    </ul>

    <h3>Trend Analysis</h3>
    <ul>
      <!-- Insights on trend analysis go here -->
    </ul>
  </div>
  `,
  engagementRate: `
  <div>
  <p>
  <!-- Insights on engagement rate go here -->
  </p>
  <h3>Recommendations</h3>
    <ul>
      <!-- recommendations go here number list goes here. limit number of recommendations to 4-->
      <!-- <li>1 Recommendation 1</li> -->
      <!-- <li>2 Recommendation 2</li> -->
      <!-- <li>3 Recommendation 3</li> -->
      <!-- <li>4 Recommendation 4</li> -->
    </ul>
  </div>
  `,

  content: `
<div>
  <p class="ai-insight-paragraph">
  <!-- Insights on content performance go here . We dont need headers-->
  </p>
</div>
  `,
};
