/**
 * @prettier
 */

import React, { FunctionComponent } from "react";
import { Router, RouteComponentProps, Redirect } from "@reach/router";

import "react-toastify/dist/ReactToastify.css";
import App from "App";
import AuthPage from "containers/AuthPage";
import Dashboard from "containers/Dashboard";
import UserManagementPage from "containers/UserManagement";
import AdminSettings from "containers/AdminSettings";
import { UserProvider } from "UserContext";
import ClientInfoPage from "containers/ClientInfo";
import BeMeasured from "containers/BeMeasured";
import HealthCheckPage from "containers/HealthCheck";
import BePlanned from "containers/BePlanned";
import NotFound from "components/NotFound";
import PrivateRoute from "hoc/PrivateRoute";
import BePlannedMonthly from "containers/BePlannedMonthly";
import BePlannedGrid from "containers/BePlannedGrid";
import ErrorBoundary from "components/ErrorBoundary";
import Activity from "containers/Activity";
import { UnsavedProvider } from "LinkContext";
import { MassEmailer } from "containers/MassEmailer/MassEmailer";
import { CreateCampaign as EmailCampaignCreation } from "components/MassEmailer/CreateCampaign";
import { CampaignReport } from "containers/MassEmailer/CampaignReport";
import OrganicCampaigns from "containers/BeAmplified/Campaigns/Organic";
import { CreateOrganicCampaign } from "containers/BeAmplified/Campaigns/Organic/CreateCampaign/AutoFollow";
import CampaignPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/CampaignPage";
import { CreateCampaign } from "components/BeAmplified/Campaigns/Organic/Create/AutoFollow/SharedCreateCampaign";
import { CreateAutoPoster } from "components/BeAmplified/Campaigns/Organic/Create/AutoPoster/Twitter";
import AutoPosterCampaignPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/AutoPosterCampaignPage";
import AutoDmCampaignPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/AutoDmCampaignPage";
import AutoLikerCampaignPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/AutoLikerCampaignPage";
import { GroupExtractor } from "components/BeAmplified/Campaigns/Organic/Create/GroupExtractor/Facebook";
import { CreateAutoDm } from "containers/BeAmplified/Campaigns/Organic/CreateCampaign/AutoDm";
import { ToastContainer } from "react-toastify";
import { CreateAutoLiker } from "containers/BeAmplified/Campaigns/Organic/CreateCampaign/AutoLiker";
import { CreateAutoCommenter } from "containers/BeAmplified/Campaigns/Organic/CreateCampaign/AutoCommenter";
import AutoCommenterCampaignPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/AutoCommenterCampaignPage";
import { CreateScrapper } from "containers/BeAmplified/Scrappers/CreateScrapper";
import { CommentsExtractor } from "containers/BeAmplified/Scrappers/instagram/CommentsExtractor";
import ExtractorPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/ExtractorPage";
import BePlannedPageV2 from "containers/BeplannedV2";
import SchedulePost from "containers/SchedulePost/SchedulePost";
import { ContentPlannerFeed } from "containers/BeplannedV2/ContentPlannerData/ContentPlannerFeed";
import Notifications from "containers/Notifications/Notifications";
import { Insight } from "containers/AiReport/Insight";
// import { GeneratedInsight } from "containers/AiReport/GeneratedInsight";

export const AppRouter: FunctionComponent<RouteComponentProps> = () => (
  <ErrorBoundary>
    <ToastContainer />
    <UserProvider>
      <UnsavedProvider>
        <Router>
          <App path="/">
            <AuthPage path="sign-in" />
            <AuthPage path="forgot-password" />
            <Dashboard path="/">
              <PrivateRoute path="/">
                <UserManagementPage path="admin-panel" />
                <AdminSettings path="admin-settings" />
                <MassEmailer path="mass-emailer" />
                <OrganicCampaigns path="campaigns/organic" />
                <EmailCampaignCreation path="mass-emailer/new-campaign" />

                <Redirect
                  from="be-planned-feed"
                  to="/content-plan-feed"
                  noThrow
                />

                <BePlanned path="content-plan-feedv2" />
                <BePlannedPageV2 path="content-plan-feedv3" />
                <ContentPlannerFeed path="content-plan-feed" />
                <Insight path="ai/dashboard" />
                {/* <GeneratedInsight path="ai/insight" /> */}
                <ContentPlannerFeed path="content-plan-feed/:uid/:cemail/:datatype" />
                <SchedulePost path="post-schedule" />
                <Notifications path="notifications" />
                <Redirect
                  from="be-planned-monthly"
                  to="/monthly-conversation-planner"
                  noThrow
                />

                <BePlannedMonthly path="monthly-conversation-planner" />
                <CreateOrganicCampaign path="campaigns/organic/create" />
                <CreateScrapper path="campaigns/scrappers/create" />
                <CreateAutoDm path="campaigns/organic/create/auto-dm" />
                <CreateAutoDm path="campaigns/organic/create/:platform/auto-dm" />
                <CreateAutoDm path="campaigns/organic/create/auto-dm/:uid/edit" />
                <CreateAutoDm path="campaigns/organic/create/:platform/auto-dm/:uid/edit" />
                <CreateAutoPoster path="campaigns/organic/create/auto-poster" />
                <CreateCampaign path="campaigns/organic/:uid/edit" />
                <CreateCampaign path="campaigns/organic/:platform/:uid/edit" />
                <GroupExtractor path="campaigns/organic/create/extractor" />
                <CommentsExtractor path="campaigns/organic/create/comments-extractor" />
                <CreateAutoLiker path="campaigns/organic/create/:platform/auto-liker" />
                <CreateAutoCommenter path="campaigns/organic/create/:platform/auto-commenter" />
                <ClientInfoPage path="client-info/:uid" />
                <CampaignReport path="mass-emailer/:campaignId" />
                <BeMeasured path="be-measured" />
                <HealthCheckPage path="health-check" />
                <BePlannedGrid path="be-planned-grid" />
                <Activity path="kit-activity" />
                <Activity path="admin-activity" />
                <OrganicCampaigns path="campaigns/organic" />
                <CampaignPage path="campaigns/organic/:uid" />
                <CampaignPage path="campaigns/organic/:platform/:uid" />
                <AutoPosterCampaignPage path="campaigns/organic/autoposter/:uid" />
                <AutoDmCampaignPage path="campaigns/organic/:platform/auto-dm/:uid" />
                <AutoLikerCampaignPage path="campaigns/organic/auto-liker/:platform/:uid" />
                <AutoCommenterCampaignPage path="campaigns/organic/auto-commenter/:platform/:uid" />
                <ExtractorPage path="campaigns/organic/extractor/:platform/:uid" />
                <NotFound default />
              </PrivateRoute>
            </Dashboard>
            <NotFound default />
          </App>
        </Router>
      </UnsavedProvider>
    </UserProvider>
  </ErrorBoundary>
);
