import React, { useState, FC, useEffect } from "react";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import "react-toastify/dist/ReactToastify.css";
import history from "history/browser";

import { PageType } from "types/PageType";
import { firestore } from "../../firebase-tools/firebase";

import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";

import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { urlDates } from "utils/dateFormat";
import BePlannedFeedV2 from "components/styled/BePlannedFeedV2";
import { Button, Grid } from "@material-ui/core";

import { useStyles } from "./styles";
import { ClientListV2 } from "components/BeMeasured/ClientListV2";
import { UserService } from "services/UserService";
import Select from "components/Common/Select";
import { navigate } from "@reach/router";
import { ReactComponent as LightBulb } from "assets/lightbulb.svg";
import Axios from "axios";
import DateRangePicker from "components/Common/DateRangePicker";
import { IAiReport, IAiReportEngagement } from "./types";
import InsightSection from "./sections/InsightSection";
import Report from "./sections/Report";
import ReportV2 from "./sections/ReportV2";

export const Insight: FC<PageType> = ({ location }) => {
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().startOf("day").toDate(),
    endDate: moment().add(6, "days").startOf("day").toDate(),
  });
  const [authUser] = useUser() as Array<IUser>;

  const [, setClient]: any = useState("");
  const [loading, setLoading] = useState(false);
  const [paramsChange, setParamsChange]: any = useState("");
  const channelOptions = ["Instagram", "Facebook", "All"];
  const [channel, setChannel]: any = useState("All");
  const [report, setReport] = useState({} as IAiReport);
  const [customer, setCustomer] = useState({} as IUser);

  const settClient = (e) => {
    const User = new UserService(authUser.id);

    return User.updateUser({
      lastClientSelection: e,
    })
      .then((res) => {
        setClient(e);
      })
      .catch((err) => {});
  };
  const labelStyle = {
    color: "#000000",
    fontWeight: 900,
    fontSize: "15px",
    fontFamily: "Avenir",
    paddingBottom: "10px",
  };

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({
      ref: userRef,
    }).document?.lastClientSelection || "";

  useEffect(() => {
    if (!!authUser?.lastClientSelection) {
      setClient(authUser?.lastClientSelection);
      setCustomer(authUser?.lastClientSelection);
    } else {
      setClient("");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);

    if (client !== "") {
      setLoading(true);
    }
  }, [dateRange, client, paramsChange, location]);

  const [getInsights, setGetInsights] = useState(true);
  const [getHistory, setGetHistory] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  return (
    <>
      {/* {!openReport && (
        <InsightSection
          client={client}
          openReport={openReport}
          setOpenReport={setOpenReport}
          setReport={setReport}
          report={report}
        />
      )} */}

 
        <ReportV2
          client={client}
        />
      
    </>
  );
};
